import React from 'react';
import logo from './logo.svg';
import './App.css';

import Amplify, { Storage } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);


function InboundAnimal(props) {
  
  
  console.log(props)
  
  var imagePath = "http://petharbor.com/get_image.asp?RES=thumb&ID=" + props.animal["Animal ID"] + "&LOCATION=ASTN";
  var adoptionLink = "http://petharbor.com/pet.asp?uaid=ASTN." + props.animal["Animal ID"];
  return <div className="animalRow">
          <div className="animalImage"><img src={imagePath} /></div>
          <div className="animalID">
            <strong>{props.animal["Name_x"] !== null ? props.animal["Name_x"] : "No name yet..." }</strong>
            <div>{props.animal["Age upon Intake"]} old {props.animal["Color_x"]} {props.animal["Breed_x"]} </div>
            <div>{props.animal["Intake Type"]} {props.animal["Intake Condition"]} {props.animal["Sex upon Intake"]}</div>
            <div>Found: {props.animal["Found Location"]} </div>
            <div>AI has predicted: <strong>{props.animal["InferredOutcome"]}</strong> </div>
            <div><a href={adoptionLink}>Interested in adoption?</a></div>
          </div>
        </div>;
}



class AnimalOutcomePredictor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          animals: [],
          inferenceLocation: ""
        }
    }
    
    componentDidMount() {
      
        Storage.get('currentIntakePredictions.json')
          .then( signedURL => {
            return fetch(signedURL)
          })
          .then(response => response.json())
          .then( inferredAnimalDataSet => {
            
            
            inferredAnimalDataSet.sort((animal1, animal2) => {
              
              if (animal1["InferredOutcome"] == "Euthanasia") 
                return -1;
              
              if (animal1["InferredOutcome"] == "Euthanasia" && animal2["InferredOutcome"] == "Disposal") 
                return -1;
                
              if (animal1["InferredOutcome"] == "Disposal" && animal2["InferredOutcome"] == "Euthanasia") 
                return 1;  
                
              if (animal1["InferredOutcome"] == "Disposal") 
                return -1;
                
              return 1; 
              
            });
            
            
            this.setState({
              animals: inferredAnimalDataSet
            });
            return;
          });
      
        // this.setState({
        //     user: this.getUserById(4)
        // })
    }
    
    render(){
      

        return( 
            <div>
            
            { this.state.animals.map(inboundAnimal => <InboundAnimal animal={inboundAnimal} />) }
            </div>
        )
        
    }
    
}

function App() {
  return (
    <div className="App">
      <div  className='aacHeader'>
        <h1>Austin Anmial Center Outcome Predictor</h1>
        <div>This is a list of animals who have arrived at the Austin Animal Center in the last 7 days (source Austin-Animal-Center-Intakes API). </div>
        <div>An AI model was built to predict the outcome of these animals and highlight those the AI deems are at the highest risk.</div>
        <div className='smallText'>This page updates every 4 hours. Built with AWS SageMaker Studio (AI), AWS Fargate (backend), and AWS Amplify (UI).</div>
      </div>
      <AnimalOutcomePredictor />
    </div>
  );
}

export default App;
