// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "aacoutcomepredictor-20191212174719-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d21abvh58v8ji7.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-2:06affcdd-8693-42c9-9cc1-b2606d75a4e7",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_xrLJLn68J",
    "aws_user_pools_web_client_id": "6edavnp5goognvlu2q73qgnoi9",
    "oauth": {},
    "aws_user_files_s3_bucket": "animalcenterstorage174323-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
